<template>
  <section>
    <div class="headbanner" :style="{ backgroundImage: `url(${bannerImage})` }">
      <v-container class="container-custom">
        <v-row>
          <v-col>
            <div class="banner">
              <div>
                <h2>Jobs By Category</h2>
                <p>
                  <span>Click at the job categories of your interest</span>
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="container-custom">
      <v-row>
        <v-col>
          <div v-if="isLoading" class="category-list">
            <div v-for="i in 10" :key="i" class="card skeleton-card">
              <v-skeleton-loader
                type="image"
                height="40"
                width="40"
                class="mb-3"
              ></v-skeleton-loader>
              <v-skeleton-loader type="text" width="80%"></v-skeleton-loader>
            </div>
          </div>
          <div
            v-else-if="
              allCategory && allCategory.results && allCategory.results.list
            "
            class="category-list"
          >
            <div v-for="(data, index) in allCategory.results.list" :key="index">
              <router-link :to="`/category/${data.slug}`">
                <div class="card">
                  <img
                    v-if="data.image"
                    :src="'https://app.necojobs.com.np' + data.image"
                    alt=""
                    style="height: 40px; width: 40px; margin-bottom: 10px"
                  />
                  <h1>{{ data.title }}</h1>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else>No categories found.</div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "category",
  data() {
    return {
      bannerImage: require("@/assets/pattern.jpg"),
      img: require("@/assets/categoryImage.png"),
      isLoading: true,
      title: "",
      adsposition: "",
      valid: true,
      limit: 100,
      skip: 1,
      id: undefined,
    };
  },
  computed: {
    ...mapGetters(["allCategory", "allMeta"]),
  },
  methods: {
    ...mapActions(["fetchCategory", "getSingleMetaPage"]),
  },
  metaInfo() {
    return {
      title: this.allMeta && this.allMeta.title,
      meta: [
        {
          name: "description",
          content: this.allMeta && this.allMeta.description,
        },
        {
          name: "keywords",
          content: this.allMeta && this.allMeta.keywords,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.necojobs.com.np/category",
        },
      ],
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.fetchCategory({
        limit: this.limit,
        skip: this.skip,
        categoryType: "job",
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      this.isLoading = false;
    }
    await this.getSingleMetaPage("category");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.headbanner {
  background-size: contain;
  height: 245px;
  width: 100%;
  background-color: #f6fdff;
  background-position: center center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-repeat: repeat-x;
}

.category-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  padding: 50px 0;

  .card {
    border: 1px solid rgb(232, 230, 230);
    background-color: #f9f9f9;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 140px;

    .icons {
      font-size: 40px;
      color: lightgray;
    }
    h1 {
      text-align: center;
      color: rgb(80, 80, 80);
      font-size: 16px;
    }

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(57, 57, 57, 0.2);
    }
  }
}

.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 30%;
  }
  h2 {
    font-size: 35px;
    font-weight: bold;
  }

  p {
    display: flex;
    color: rgb(75, 75, 75);
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .headbanner {
    height: auto;
  }

  .banner {
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
    }
  }

  .category-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
}

.boxinside {
  box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
  transition: width 235ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
  background: $white;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.skeleton-card {
  border: 1px solid rgb(232, 230, 230);
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 140px;
}

/* Override v-skeleton-loader background color */
::v-deep .v-skeleton-loader__image,
::v-deep .v-skeleton-loader__text {
  background-color: #e0e0e0 !important;
}
</style>
